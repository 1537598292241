<template>
  <div class="pwa-block">
    <atomic-image class="pwa-block__desktop-image" :src="getLabel('image')"></atomic-image>
    <div class="pwa-block__content">
      <div class="pwa-block__title">
        {{ getLabel('title') }}
      </div>
      <atomic-image class="pwa-block__mobile-image" :src="getLabel('image')"></atomic-image>
      <div class="pwa-block__list">
        <div
          class="pwa-block__list-item"
          v-html="DOMPurify.sanitize(marked.parseInline(firstItemHTML) as string, { FORBID_TAGS: ['style'] })"
        />
        <div
          class="pwa-block__list-item"
          v-html="DOMPurify.sanitize(marked.parseInline(secondItemHTML) as string, { FORBID_TAGS: ['style'] })"
        />
      </div>
    </div>
    <div class="pwa-block__actions">
      <button-base class="pwa-block__button install-button" type="secondary-1" size="md" @click="installPWA">{{
        getLabel('installButton')
      }}</button-base>
      <div class="desktop-actions">
        <button-base v-if="isVisible" class="pwa-block__button" type="secondary-1" size="md" @click="installPWA">
          <atomic-icon id="desktop" />
          {{ getLabel('installForPCButton') }}</button-base
        >
        <button-base class="pwa-block__button" type="secondary-1" size="md" @click="showQrModal">
          <atomic-icon id="phone" />{{ getLabel('installForMobileButton') }}</button-base
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import presentSvg from 'assets/icons/empty/present.svg?raw';
  import cashSvg from 'assets/icons/empty/cash-pwa.svg?raw';
  import DOMPurify from 'isomorphic-dompurify';
  import { marked } from 'marked';
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent } = useProjectMethods();
  const getLabel = (path: string): string =>
    getContent(layoutData?.pwa?.block, defaultLocaleLayoutData?.pwa?.block, path);

  const { isVisible, installPWA, showQrModal } = usePwaInstall();

  const firstItemHTML = computed(() =>
    getLabel('firstItem')
      .replace('{goldenPart}', `<span class="golden-part">${getLabel('firstItemGoldenPart')}</span>`)
      .replace('{icon}', `<div class="svg-icon">${presentSvg}</div>`)
  );
  const secondItemHTML = computed(() =>
    getLabel('secondItem')
      .replace('{goldenPart}', `<span class="golden-part">${getLabel('secondItemGoldenPart')}</span>`)
      .replace('{icon}', `<div class="svg-icon">${cashSvg}</div>`)
  );
</script>
<style src="~/assets/styles/components/pwa/block.scss" lang="scss" />
